import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import UserExperior from "user-experior-web";

async function customFetch(url, options) {
  const ue = new UserExperior();
  if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
    ue.startRecording(Cookies.get('parameter_value'), {
      sessionReplay: { 
          maskAllInputs: true,
          maskInputOptions: {
              password: true,
              email: true,
              tel: true,
              color: false,
              date: false,
              'datetime-local': false,
              month: false,
              number: false,
              range: false,
              search: false,
              text: true,
              time: false,
              url: false,
              week: false,
              textarea: false,
              select: false,
          }
      }
    });
    ue.setUserIdentifier(`${localStorage.getItem("in_username")}_${process.env.REACT_APP_ENVIRONMENT}`);
    let body = JSON.stringify(options.body)
    let urlName = url.split('/');
    let eventLog = {
      'body' : body,
      'headers': JSON.stringify(options.headers),
      'method': options.method,
      'url': url
    }
    ue.logEvent(urlName[urlName.length - 1], eventLog);
  }
  options.headers["api-token"] = localStorage.getItem("access_token");
  return await isTokenExpired(url, options);
  //return await call_api(url, options);
}

const isTokenExpired = async (url, options) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    var decoded = jwt_decode(token);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decoded.exp * 1000 < currentDate.getTime()) {
      return await generate_token(url, options);
    } else {
      return await call_api(url, options);
    }
  }
};

const generate_token = async (url, options) => {
  const formdata = new FormData();
  if (Cookies.get("refresh_token")) {
    var decode_reftoken = jwt_decode(Cookies.get("refresh_token"));
    let currentDate = new Date();

    if (decode_reftoken.exp * 1000 < currentDate.getTime()) {
      Cookies.remove("refresh_token");
      localStorage.clear();
      window.location.href = "/";
    } else {
      formdata.append("refresh_token", Cookies.get("refresh_token"));
      try {
        const response = await fetch(process.env.REACT_APP_URL + "/get/access/token", {
          method: "POST",
          body: formdata,
        });

        if (response.ok) {
          const data3 = await response.json();
          console.log("access_token->", data3.access_token);
          localStorage.setItem("access_token", data3.access_token);
          options.headers["api-token"] = data3.access_token;
          return await call_api(url, options);
        } else {
          console.log("Error fetching token:", response);
        }
      } catch (error) {
        console.log("Error fetching token:", error);
      }
    }
  }
};

const call_api = async (url, options) => {
  const ue = new UserExperior();
  if(Cookies.get('parameter_value') !== 'null' && Cookies.get('parameter_value') !== undefined) {
    ue.startRecording(Cookies.get('parameter_value'), {
      sessionReplay: { 
          maskAllInputs: false,
          maskInputOptions: {
              password: false,
              email: false,
              tel: false,
              color: false,
              date: false,
              'datetime-local': false,
              month: false,
              number: false,
              range: false,
              search: false,
              text: false,
              time: false,
              url: false,
              week: false,
              textarea: false,
              select: false,
          }
      }
    });
  }
  console.log("call_api->", url + "==" + options.headers["api-token"]);
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      console.log("Network response was not ok:", response);
      if (response.status !== 200) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    const jsonData = await response;
    return jsonData;
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};

export default customFetch;
