import React from "react";
import { requestForToken } from "./firebase";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import TO_slip from "./components/TO_slip";
import To_slip_verification from "./components/To_slip_verification";
import To_deposit_slip from "./components/To_deposit_slip";
import Transaction_history from "./components/Transaction_history";
import NACH_Household_list from "./components/NACH_Household_list";
import Physical_NACH_register from "./components/Physical_NACH_register";
import Nach_type from "./components/Nach_type";
import Nach_success from "./components/Nach_success";
import test from "./components/test";
import NACH_Download from "./components/NACH_Download";
import NACH_Pull_Download from "./components/NACH_Pull_Download";
import NACH_Mandate_Registration from "./components/NACH_Mandate_Registration";
import NACH_Preparation_Forpull from "./components/NACH_Preparation_Forpull";
import NACH_Fail_Cases from "./components/NACH_Fail_Cases";
import NACH_Deactivate from "./components/NACH_Deactivate";
import SupportTicket from "./components/SupportTicket";
import SupportTicketForm from "./components/SupportTicketForm";
import SupportTicketList from "./components/SupportTicketList";
import PartnerLOSDownloads from "./components/PartnerLOSDownloads";
import Disbursement from "./components/Disbursement";
import { FilterContextProvider } from "./context/FilterToDepositSlip";
import { PermissionsProvider } from "./components/permissionComponents/PermissionContext";

import Utr from "./components/utr";
import UPI_auto_pay from "./components/UPI_autopay";

import "./assets/css/main.css";
import EkycRefno from "./components/EkycRefno";
require("dotenv").config();
//import Notification from './Notification';
function App() {
  //console.log("API URL",process.env.REACT_APP_URL);
  // const uid = localStorage.getItem("in_userid");
  // if(!uid){
  //   localStorage.clear();
  //   this.props.history.push('/')
  // }
  // window.onpopstate = function() {
  //   window.location.reload();
  // };
  // ********************************************************************
  function perfObserver(list, observer) {
    list.getEntries().forEach((entry) => {
      console.log("entry.entryType=>", entry.entryType);
      if (entry.entryType === "mark") {
        console.log(
          "Performance Observer =>",
          `${entry.name}'s startTime: ${entry.startTime}`
        );
      }
      if (entry.entryType === "measure") {
        console.log(
          "Performance Observer =>",
          `${entry.name}'s duration: ${entry.duration}`
        );
      }
    });
  }
  const observer = new PerformanceObserver(perfObserver);
  observer.observe({ entryTypes: ["measure", "mark"] });

  // ***********************************************************************

  const po = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      // Log the entry and all associated details.
      console.log("longtask =>", entry.toJSON());
    }
  });
  // Start listening for `longtask` entries to be dispatched.
  po.observe({ type: "longtask", buffered: true });

  // ***********************************************************************

  const po1 = new PerformanceObserver((entryList) => {
    for (const entry of entryList.getEntries()) {
      // Log the entry and all associated details.
      console.log("Element Time =>", entry.toJSON());
    }
  });
  // Start listening for `element` entries to be dispatched.
  po1.observe({ type: "element", buffered: true });

  // ***********************************************************************
  const po2 = new PerformanceObserver((entryList) => {
    const firstInput = entryList.getEntries()[0];

    // Measure First Input Delay (FID).
    const firstInputDelay = firstInput.processingStart - firstInput.startTime;

    // Measure the time it takes to run all event handlers
    // Note: this does not include work scheduled asynchronously using
    // methods like `requestAnimationFrame()` or `setTimeout()`.
    const firstInputProcessingTime =
      firstInput.processingEnd - firstInput.processingStart;

    // Measure the entire duration of the event, from when input is received by
    // the browser until the next frame can be painted after processing all
    // event handlers.
    // Note: similar to above, this value does not include work scheduled
    // asynchronously using `requestAnimationFrame()` or `setTimeout()`.
    // And for security reasons, this value is rounded to the nearest 8ms.
    const firstInputDuration = firstInput.duration;

    // Log these values the console.
    console.log("Event Time =>", {
      firstInputDelay,
      firstInputProcessingTime,
      firstInputDuration,
    });
  });

  po2.observe({ type: "first-input", buffered: true });
  // ********************************************************************************

  const po3 = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      // If transferSize is 0, the resource was fulfilled via the cache.
      console.log("Resource Time => ", entry.name, entry.transferSize === 0);
    }
  });
  // Start listening for `resource` entries to be dispatched.
  po3.observe({ type: "resource", buffered: true });

  // ********************************************************************************

  const po4 = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      // If transferSize is 0, the resource was fulfilled via the cache.
      console.log("Navigation Time to first byte", entry.responseStart);
    }
  });
  // Start listening for `navigation` entries to be dispatched.
  po4.observe({ type: "navigation", buffered: true });

  // ********************************************************************************

  const po5 = new PerformanceObserver((list) => {
    for (const entry of list.getEntries()) {
      // Logs all server timing data for this response
      console.log("Server Timing => ", entry.serverTiming);
    }
  });
  // Start listening for `navigation` entries to be dispatched.
  po5.observe({ type: "navigation", buffered: true });

  // ********************************************************************************
  requestForToken();
  return (
    <>
    <Router basename="/">
      <PermissionsProvider>
        <FilterContextProvider>
          <React.StrictMode>
            <Switch>
              <Route exact={true} path="/dashboard" component={Dashboard} />
              <Route exact={true} path="/TO_slip" component={TO_slip} />
              <Route
                exact={true}
                path="/To_deposit_slip"
                component={To_deposit_slip}
              />
              <Route
                exact={true}
                path="/To_slip_verification"
                component={To_slip_verification}
              />
              <Route
                exact={true}
                path="/Transaction_history"
                component={Transaction_history}
              />
              <Route
                exact={true}
                path="/NACH_Household_list"
                component={NACH_Household_list}
              />
              <Route
                exact={true}
                path="/Physical_NACH_register"
                component={Physical_NACH_register}
              />
              <Route exact={true} path="/Nach_type" component={Nach_type} />
              <Route exact={true} path="/test" component={test} />
              <Route
                exact={true}
                path="/Nach_success"
                component={Nach_success}
              />
              <Route
                exact={true}
                path="/NACH_Preparation_Forpull"
                component={NACH_Preparation_Forpull}
              />
              <Route
                exact={true}
                path="/NACH_Download"
                component={NACH_Download}
              />
              <Route
                exact={true}
                path="/NACH_Pull_Download"
                component={NACH_Pull_Download}
              />
              <Route
                exact={true}
                path="/NACH_Mandate_Registration"
                component={NACH_Mandate_Registration}
              />
              <Route
                exact={true}
                path="/NACH_Fail_Cases"
                component={NACH_Fail_Cases}
              />
              <Route
                exact={true}
                path="/NACH_Deactivate"
                component={NACH_Deactivate}
              />
              <Route exact path="/ticketdetails" component={SupportTicket} />
              <Route
                exact
                path="/supportticketlist"
                component={SupportTicketList}
              />
              <Route
                exact
                path="/createsupportticket"
                component={SupportTicketForm}
              />
              <Route
                exact={true}
                path="/PartnerLOSDownloads"
                component={PartnerLOSDownloads}
              />

              <Route
                exact={true}
                path="/Disbursement"
                component={Disbursement}
              />

              <Route exact={true} path="/utr" component={Utr} />

              <Route
                exact={true}
                path="/upi_autopay"
                component={UPI_auto_pay}
              />

              <Route
                exact={true}
                path="/ekyc"
                component={EkycRefno}
              />

              <Route exact={true} path="/" component={Login} />
            </Switch>
          </React.StrictMode>
          </FilterContextProvider>
        </PermissionsProvider>
      </Router>
    </>
  );
}

export default App;
