import React, { Component } from "react";
import { logout } from "./Utility";
import { EditOutlined } from "@ant-design/icons";

class EkycRefno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      inputFields: [{ id: 1, ekyc: "", refno: "" }],
      isErrOpen: false,
      apiErrorMessage: "",
    };
  }

  handleInputChange = (index, fieldName, value) => {
    const { inputFields } = this.state;
    const updatedFields = [...inputFields];
    updatedFields[index][fieldName] = value;
    this.setState({ inputFields: updatedFields });
  };

  handleAddFields = () => {
    const { inputFields } = this.state;
    const newField = {
      id: inputFields[inputFields.length - 1].id + 1,
      ekyc: "",
      refno: "",
    };
    this.setState({ inputFields: [...inputFields, newField] });
  };

  handleRemoveFields = (index) => {
    const { inputFields } = this.state;
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    this.setState({ inputFields: updatedFields });
  };

  isSubmitDisabled = () => {
    // const { inputFields } = this.state;
    // return inputFields.some((field) => !field.ekyc || !field.refno);

    const { inputFields } = this.state;

    const isInvalidField = (field) => {
      return (
        !field.ekyc ||
        !field.refno ||
        field.ekyc.length !== 8 ||
        field.refno.length !== 16
      );
    };

    const hasInvalidField = inputFields.some(isInvalidField);

    if (hasInvalidField) {
      // Handle the case where there is at least one invalid field
      console.log("Invalid data found!");
      return true;
    } else {
      // Proceed with the logic when all fields are valid
      console.log("All fields are valid.");
      return false;
    }
  };

  submitData = () => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    const { inputFields } = this.state;
    const form = new FormData();
    const _body = JSON.stringify(inputFields);
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token
      },
      body: _body,
    };

    fetch(
      process.env.REACT_APP_URL + "/partner/assign/ekyc/refno",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        this.setState({
          isErrOpen: true,
          apiErrorMessage: response.message,
        });

        const updatedData = this.state.inputFields.map((item) => {
          const matchingItem = response.data.find(
            (item1) => item1.ekyc === item.ekyc
          );

          if (matchingItem) {
            // Merge the properties from data1 into data
            return {
              ...item,
              object_id: matchingItem.object_id,
              status: matchingItem.status,
            };
          }

          // If no match found, return the original item
          return item;
        });

        // Now, updatedData contains the updated information based on matching ekyc values
        console.log("updatedData", updatedData);
        this.setState({
          inputFields: updatedData,
        });
      });
  };
  render() {
    // console.log("inputFields", this.state.inputFields);
    const { loading, inputFields, isErrOpen, apiErrorMessage } = this.state;

    return (
      <div className="container">
        <div className="utr_navbar">
                <button className='button back_button' onClick={() => this.props.history.goBack()}>
                    <img
                    src={require("../assets/images/back.png")}
                    />
                </button>
                <h3>Ekyc Refno</h3>
                <button className='button' onClick={logout}>
                    <img
                        src={require("../assets/images/power-off.png")}
                        alt=""
                    />
                </button>
            </div>    
        {loading && (
          <div class="load-4">
            <div class="ring-1"></div>
          </div>
        )}
        <div
          className={
            isErrOpen
              ? "modal fade show d-block mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({
                    isErrOpen: false,
                  });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="popuperrtext">{apiErrorMessage}</p>
            </div>
          </div>
        </div>

        <div className="mt-4">
          {inputFields.map((field, index) => (
            <div key={field.id} className="mt-2 row">
              <input
                type="text"
                placeholder="Ekyc (8 Digit)"
                className="form-control col-md-4"
                value={field.ekyc}
                onChange={(e) =>
                  this.handleInputChange(index, "ekyc", e.target.value)
                }
              />

              <input
                type="text"
                placeholder="Reference No (16 Digit)"
                className="form-control col-md-4 ml-1"
                value={field.refno}
                onChange={(e) =>
                  this.handleInputChange(index, "refno", e.target.value)
                }
              />
              {field.status == 1 && (
                <button
                  type="button"
                  className="btn btn-info ml-1 col-md-1"
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/payload",
                      state: {
                        api_token: this.props.location.state.api_token,
                        effective_designation_id: this.props.location.state
                          .effective_designation_id,
                        object_id: field.object_id,
                      },
                    })
                  }
                >
                  <EditOutlined />
                </button>
              )}
              {inputFields.length - 1 === index && (
                <button
                  type="button"
                  className="btn btn-primary ml-1 col-md-1"
                  onClick={() => this.handleAddFields()}
                >
                  +
                </button>
              )}
              {inputFields.length !== 1 && (
                <button
                  className="btn btn-danger ml-1 col-md-1"
                  type="button"
                  onClick={() => this.handleRemoveFields(index)}
                >
                  -
                </button>
              )}
            </div>
          ))}
          <div className="row mt-2">
            <button
              className="btn btn-primary col-md-3"
              disabled={this.isSubmitDisabled()}
              onClick={() => this.submitData()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default EkycRefno;
