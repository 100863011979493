export function logout(otherscreen = null) {
  console.log(api_token);
  let api_token = localStorage.getItem("api_token");
  fetch(process.env.REACT_APP_URL + "/logout", {
    method: "POST",
    headers: { "api-token": api_token },
    //body: formdata,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (otherscreen != null) {
        window.history.back();
      } else {
        //const formdata = new FormData();
        console.log(data);
        localStorage.clear();
        window.location.href = "/";
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function netspeed() {
  return navigator.connection.downlink > 1
    ? navigator.connection.downlink + " Mb/s"
    : navigator.connection.downlink + " Kb/s";
}
