import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ChatSupportBtn from "./ChatSupportBtn";
import moment from "moment";
// import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";
import { logout } from "./Utility";
import { Select, Tooltip } from "antd";
import customFetch from "./apicall/api";
import Cookies from "js-cookie";
import { PESelect } from "./permissionComponents";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
var api_token = "",
  hh_no = "";

class NACH_Household_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api_t: "",
      datafordis: "",
      searchid: "",
      isSpin: false,
      cnames: [],
      isCreateuser: false,
      nachtyp: "",
      collection_ac: "",
      partnerpayment: [],
      multipartnerpayment: [],
      pgdata: [],
      productdata: null,
      hhid: "",
      acno: "",
      ifsc: "",
      hhno: "",
      name_as_per_account: "",
      account_type_name: "",
      loan_id: "",
      bank_name: "",
      isNACHMessage: "",
      isNACH: false,
      time_start: "",
      isView: false,
      viewFile: "",
      isConfirmdisable: false,
      isTokenDel: false,
      isOrderid: false,
      physical_nach_order_id: "",
      nach_status_type_flag: "",
      nach_type: "",
      NACH_form_upload: "",
      ISNACH_form_upload: false,
      cpn: 0,
      showSearch: false,
      SMSemailSuccess: "",
      isSMSemail: false,
      nach_partner_id: "",
      user_id: "",
      nach_status: [],
      nach_history: [],
      temp_loan_id: "",
      otherScreen: "",
      bankNachData: [],
      isAndroid: false,
      disableBTN: true,
      productlist: [],
      product_id: null,
    };
    //this.setNACHType = this.setNACHType.bind(this);
    this.setNACHHousehold = this.setNACHHousehold.bind(this);
    this.markExpire = this.markExpire.bind(this);
    // this.clearPhysicalNACH = this.clearPhysicalNACH.bind(this);
  }

  //  AT-2110 | Vaibhav Sawant | 06-07-2023 | Functionality to allow to Expire Physical NACH for FIN-OPS

  markExpire(bank_id, order_id) {
    this.setState({
      isSpin: true,
    });

    const formdata = new FormData();
    formdata.append("bank_id", bank_id);
    formdata.append("order_id", order_id);

    customFetch(process.env.REACT_APP_URL + "/mark/nach/expire", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isNACH: true,
            isNACHMessage: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          isNACH: true,
          isNACHMessage: json.message,
          isSpin: false,
        });
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
  }

  // AT-2461 | Vaibhav Sawant | 31-10-2023 | Button added to clear cache for particular household_number
  clearCache(household_number) {
    this.setState({
      isSpin: true,
    });

    const formdata = new FormData();
    formdata.append(
      "multiple_keys",
      '(get_nach_registration_history:{"household_number": "' +
        household_number +
        '"},get_household_list:{"household_number": "' +
        household_number +
        '"})'
    );

    fetch(process.env.REACT_APP_URL + "/clear/cache", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isNACH: true,
            isNACHMessage: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          isNACH: true,
          isNACHMessage: json.message,
          isSpin: false,
        });
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
  }

  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      this.setState({ isAndroid: true });
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false });
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });
        localStorage.setItem("access_token", tokenData.access_token);
        // if (tokenData.userid != "" && tokenData.access_token != "") {
        //   localStorage.setItem("in_userid", tokenData.userid);
        //   localStorage.setItem("api_token", tokenData.access_token);
        //   localStorage.setItem("access_token", tokenData.access_token);
        //   this.props.history.push({
        //     pathname: "/Dashboard",
        //     state: {
        //       api_token: tokenData.access_token,
        //       password_age: 1,
        //     },
        //   });
        // }
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
      // Handle the error gracefully
      // if (localStorage.getItem("access_token")) {
      //   fetch(process.env.REACT_APP_URL + "/new/logout", {
      //     method: "POST",
      //     headers: { "api-token": localStorage.getItem("access_token") },
      //   })
      //     .then((res) => res.json())
      //     .then((data) => {
      //       console.log(data.userid);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
    }
    this.checkForRequest();
    // this.sanctionedcases();
    // this.getnachpartner();
    // const uid = localStorage.getItem("in_userid");

    // console.log(uid);
  }
  getnachstatuslist() {
    this.setState({ isSpin: true, time_start: moment() });
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method

    customFetch(process.env.REACT_APP_URL + "/get/nach/status/type/details", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })

      .then((json) => {
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        // console.log(json);
        this.setState({
          nach_status: json,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
  }
  checkForRequest() {
    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);

    api_token = params.get("api_token");
    // console.log("url_data-->", api_token);
    hh_no = params.get("lead_id");
    let user_id = "";

    if (api_token == null || hh_no == null) {
      // console.log("HELLOOops.location.state.api_token);
      const { effective_designation_id } =
        (this.props.location && this.props.location.state) || {};
      this.state.effective_designation_id = effective_designation_id;
      api_token =
        (this.props.location && this.props.location.state.api_token) || {};
      localStorage.setItem("access_token", api_token);
      this.state.user_id = localStorage.getItem("in_userid");
      this.state.showSearch = true;
    } else {
      user_id = params.get("user_id");
      // console.log("url_data-->", user_id);
      if (user_id == null || user_id == "null" || user_id == "") {
        this.props.history.goBack();
      } else {
        localStorage.setItem("access_token", api_token);
        localStorage.setItem("api_token", api_token);
        localStorage.setItem("in_userid", user_id);
        localStorage.setItem("effective_designation", params.get("e_id"));
        localStorage.setItem("branch_id", params.get("b_id"));
        this.state.effective_designation_id = params.get("e_id");
        this.state.datafordis = "hhid";
        this.state.searchid = hh_no;
        this.state.showSearch = false;
        this.state.user_id = user_id;
        this.setState({
          otherScreen: 1,
        });
        this.fetchDetails();
        this.getnachstatuslist();
      }
    }

    this.getnachstatuslist();
    this.getnachpartner();
  }

  getproductlist(household_no) {
    customFetch(
      process.env.REACT_APP_URL +
        "/get/household/productlist?household_number=" +
        household_no,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
          });
        }
        return res.json();
      })
      .then((json) => {
        this.setState({ partnerpayment: [] }, () => {
          json.map((proddata) => {
            this.getnachpayment(proddata.product_id, json.length);
          });
        });
        // if (json && json.length == 1) {
        //   this.setState(
        //     {
        //       product_id: json[0].product_id,
        //     },
        //     () => this.getnachpayment()
        //   );
        // } else {
        //   this.setState({
        //     disableBTN: true,
        //     errMsg: "Multiple active loans present",
        //   });
        // }
        this.state.isSpin = false;
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
  }

  getnachpartner() {
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    this.state.isSpin = true;
    customFetch(process.env.REACT_APP_URL + "/get/nach/partner/list", {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })

      .then((json) => {
        // console.log("Partner:" + json);
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          nachpartner: json,
        });
      })
      .catch((error) => console.log("error", error));
  }

  setCookieData(productId, productList) {
    const currentData = Cookies.get("nachProductPartnerMap");
    let data = currentData ? JSON.parse(currentData) : {};

    data[productId] = {
      product_list: productList,
      expiry: new Date().getTime() + 3600 * 24000 * 7,
    };
    Cookies.set("nachProductPartnerMap", JSON.stringify(data), { expires: 7 });
  }

  getCookieData(productId) {
    const cookieData = Cookies.get("nachProductPartnerMap");
    if (cookieData) {
      const data = JSON.parse(cookieData);
      const currentTime = new Date().getTime();
      if (data[productId] && currentTime <= data[productId].expiry) {
        return data[productId].product_list;
      }
    }
    return null;
  }

  getnachpayment = (product_id, prodlength) => {
    const cookieData = this.getCookieData(product_id);

    if (cookieData) {
      this.setState({
        partnerpayment: cookieData,
      });
      this.state.isSpin = false;
      this.forceUpdate();
    } else {
      this.setState({ isSpin: true });
      customFetch(
        process.env.REACT_APP_URL +
          "/get/partner/payment/list?product_id=" +
          product_id,
        {
          method: "GET",
          headers: { "api-token": api_token },
        }
      )
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
            });
          }
          return res.json();
        })

        .then((json) => {
          // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
          //   localStorage.clear();
          //   this.props.history.push("/");
          // }
          this.setCookieData(product_id, json);
          if (prodlength > 1) {
            this.setState(
              (prevState) => ({
                multipartnerpayment: [...prevState.multipartnerpayment, json],
              }),
              () => {
                this.combinedata();
              }
            );
          } else {
            this.setState({
              partnerpayment: json,
            });
          }
          this.state.isSpin = false;
          this.forceUpdate();
        })
        .catch((error) => console.log("error", error));
    }
  };
  combinedata = () => {
    const combinedResults = this.state.multipartnerpayment
      .filter((result) => result !== null)
      .reduce((acc, current) => {
        return acc.concat(current);
      }, []);

    const processedData = [];
    const partnerIds = new Set();

    combinedResults.forEach((item) => {
      if (!partnerIds.has(item.partner_id)) {
        partnerIds.add(item.partner_id);
        processedData.push(item);
      }
    });

    this.setState(
      {
        partnerpayment: processedData,
      },
      () => console.log("partnerpayment--", this.state.partnerpayment)
    );
  };
  // setNACHType(e) {
  //   this.state.nachtyp = e.target.value;
  //   this.forceUpdate();
  // }

  fetchDetails() {
    this.setState({ isSpin: true, time_start: moment() });
    this.state.cnames = [];
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    const formdata = new FormData();
    let queryparam = "?";
    if (this.state.searchid != null && this.state.searchid != "") {
      if (this.state.datafordis === "loanid") {
        //formdata.append("loan_id", this.state.searchid);
        queryparam += "loan_id=" + this.state.searchid + "&";
      }
      if (this.state.datafordis === "hhid") {
        //formdata.append("household_number", this.state.searchid);
        queryparam += "household_number=" + this.state.searchid + "&";
      }
    }
    if (this.state.searchdate != null && this.state.searchdate != "") {
      //formdata.append("ageing", this.state.searchdate);
      queryparam += "ageing=" + this.state.searchdate + "&";
    }
    if (this.state.searchstatus != null && this.state.searchstatus != "") {
      //formdata.append("nach_status_type_flag", this.state.searchstatus);
      queryparam += "nach_status_type_flag=" + this.state.searchstatus + "&";
    }
    if (this.state.searchtype != null && this.state.searchtype != "") {
      //formdata.append("nach_type", this.state.searchtype);
      queryparam += "nach_type=" + this.state.searchtype;
    }
    customFetch(process.env.REACT_APP_URL + "/get/householdlist" + queryparam, {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })

      .then((json) => {
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        let unique = json.filter(
          (json, index, self) =>
            index === self.findIndex((t) => t.bank_id === json.bank_id)
        );

        this.setState({
          cnames: unique,
        });

        // Vaibhav Sawant | 31-08-2023 | Filtering nach data to their respective bank_id, and same order_id get passed for which the form is getting uploaded
        this.state.bankNachData = [];

        unique.map((item) => {
          let tempDict = {};
          tempDict["bank_id"] = item.bank_id;
          tempDict["nach_data"] = [];
          this.state.bankNachData.push(tempDict);
        });

        // console.log(this.state.bankNachData);

        // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
        const params = {
          household_number: this.state.searchid,
        };
        const queryParams = new URLSearchParams(params).toString();
        customFetch(
          process.env.REACT_APP_URL +
            "/get/nach/registration/history?" +
            queryParams,
          {
            method: "GET",
            headers: { "api-token": api_token },
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((json) => {
            // if (
            //   json.api_code === 4 ||
            //   json.api_code === 3 ||
            //   json.api_code === 5
            // ) {
            //   localStorage.clear();
            //   this.props.history.push("/");
            // }

            json.map((item) => {
              this.state.bankNachData.map((nitem) => {
                if (nitem.bank_id === item.bank_id) {
                  nitem["nach_data"].push(item);
                }
              });
            });

            this.setState({
              isSpin: false,
              nach_history: json,
            });
          })
          .catch((error) => console.log("error", error));
      })
      .catch((error) => console.log("error", error));
  }

  showNACHPopup(item) {
    if (item.bank_status == "A") {
      let filteredData = this.state.bankNachData
        .filter(
          (itemdata) =>
            itemdata.bank_id === item.bank_id && itemdata.nach_data !== null
        )
        .flatMap((filteritem) => filteritem.nach_data)
        .filter(
          (nach) =>
            nach.nach_status_type_flag !== "E" &&
            nach.nach_status_type_flag !== "J"
        )
        .map((nach) => ({
          nach_type: nach.nach_type,
          partner_vendor_payment_account_id:
            nach.partner_vendor_payment_account_id,
          nach_flag: nach.nach_status_type_flag,
        }));
      this.setState(
        {
          filteredData: filteredData,
          prevloans: [],
          isCreateuser: true,
          hhid: item.household_id,
          acno: item.account_number,
          ifsc: item.ifsc,
          hhno: item.household_number,
          name_as_per_account: item.name_as_per_account,
          account_type_name: item.account_type_name,
          bank_name: item.bank_name,
          bank_id: item.bank_id,
          nach_status_type_flag: item.nach_status_type_flag,
          partner_vendor_payment_account_id:
            item.partner_vendor_payment_account_id,
          physical_nach_order_id: item.physical_nach_order_id,
          nach_type: item.nach_type,
          nach_partner_id: item.nach_partner_id,
          productdata: null,
          partnerpayment: [],
          pgdata: [],
          productlist: [],
          product_id: null,
          isNACH: false,
          nachtyp: "",
          errMsg: "",
          disableBTN: true,
          collection_ac: "",
        },
        () => {
          this.getproductlist(item.household_number);
        }
      );
      // this.getnachpayment(item.household_number);
      // this.getpg();
    } else {
      this.setState({
        isNACH: true,
        isNACHMessage: "Bank Account is not Approved!",
      });
    }
  }

  getpg() {
    // loan_documentation_id: loan_number,
    // const name = e.target.name;
    // let value = e.target.value;
    // this.setState({ [name]: value });
    this.state.sort_data = [];

    for (let i = 0; i < this.state.partnerpayment.length; i++) {
      // this.state.pgdata.push(this.state.partnerpayment[i]);
      if (
        this.state.partnerpayment[i].partner_id == this.state.productdata //parseInt(e.target.value)
      ) {
        this.state.sort_data.push(this.state.partnerpayment[i].collection_ac);
        this.setState({
          pgdata: this.state.partnerpayment[i].payment_gateway,
        });
      }
    }

    //this.state.nachtyp = "";
    this.state.collection_ac = "";
    this.forceUpdate();
  }

  setNACHHousehold() {
    this.state.isSpin = true;
    this.forceUpdate();
    // const uid = localStorage.getItem("in_userid");
    const formdata = new FormData();
    if (
      this.state.user_id == "" ||
      this.state.user_id == null ||
      this.state.user_id == "null"
    ) {
      this.props.history.goBack();
    }

    // console.log(this.state);
    formdata.append("user_id", this.state.user_id);
    formdata.append("bank_id", this.state.bank_id);
    formdata.append("map_id", this.state.collection_ac.split("~")[1]);
    formdata.append("bank_acc_no", this.state.acno);
    formdata.append("nach_type", this.state.nachtyp);
    formdata.append("ifsc_code", this.state.ifsc);
    formdata.append("beneficiary_name", this.state.name_as_per_account);
    let tmp_acc_type = "";
    // AT-2222 | Vaibhav Sawant | 26-07-2023 | Account type as Jan-Dhan will go as savings for nach registration
    if (
      this.state.account_type_name === "Savings" ||
      this.state.account_type_name === "Jan-Dhan"
    ) {
      tmp_acc_type = "savings";
    } else if (this.state.account_type_name === "Current") {
      tmp_acc_type = "current";
    }
    formdata.append("acc_type", tmp_acc_type);
    formdata.append("hhno", this.state.hhno);
    formdata.append("hhid", this.state.hhid);

    // console.log((this.state.nach_partner_id === null || this.state.nach_partner_id === "") ? this.state.collection_ac.split("~")[1] : this.state.nach_partner_id);
    // return;
    // if (this.state.cpn) {

    //   this.state.isNACH = true;
    //   this.state.isNACHMessage = "Physical NACH Already Present, Please Clear First!";
    //   this.state.isSpin = false;
    //   this.state.isCreateuser = false;

    //   this.forceUpdate();

    // } else {
    customFetch(process.env.REACT_APP_URL + "/set/nach/household/bank", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        this.state.isSpin = false;

        if (json.status == 1) {
          this.state.isNACHMessage =
            "Order ID: " + json.order_id + ", created!";
        } else {
          this.state.isNACHMessage = json.message;
        }

        this.state.isSpin = false;
        this.state.isNACH = true;
        this.state.isCreateuser = false;
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
    // }

    // console.log(this.state.bank_id);
    // console.log(this.state.collection_ac);
  }

  updata() {
    this.fetchDetails();
  }

  viewclick(path) {
    this.setState({ isSpin: true, time_start: moment() });
    // const { api_token, household_id } =
    //   (this.props.location && this.props.location.state) || {};
    //let path = this.state.bankac.split("~")[3];
    // console.log("Start Time", moment());

    // console.log(path);

    if (path) {
      if (path.includes("Uploaded")) {
        // console.log(path);
        this.setState({
          isNACHMessage: path,
          isNACH: true,
          isSpin: false,
        });
        this.forceUpdate();
      } else {
        const formdata = new FormData();
        //formdata.append("bucket_name", path.split("/")[0]);
        //formdata.append("blob_name", path.split("/")[4]);
        formdata.append("blob_url", path);
        const requestOptions = {
          method: "POST",
          headers: {
            "api-token": api_token,
          },
          body: formdata,
        };
        //console.log("Body",formdata);
        customFetch(
          process.env.REACT_APP_URL + "/get/public/url",
          requestOptions
        )
          .then((res) => {
            if (res.headers.get("api_code") > 0) {
              this.setState({
                isErrOpen: true,
                api_error_message: res.headers.get("api_error_message"),
                isSpin: false,
              });
            }

            // if (
            //   res.api_code === 4 ||
            //   res.api_code === 3 ||
            //   res.api_code === 5
            // ) {
            //   localStorage.clear();
            //   this.props.history.push("/");
            // }
            return res.json();
          })
          .then((img) => {
            //URL.createObjectURL(img)
            //const url = window.URL.createObjectURL(img);
            this.setState({
              viewFile: img.public_url,
              isView: true,
              isSpin: false,
            });
          })
          .catch((error) => console.log("error", error));
      }
      // console.log("End Time", moment());
    } else {
      this.setState({
        viewFile: "",
        isView: true,
        isSpin: false,
      });
    }
  }

  deleteToken(tid) {
    this.setState({
      isConfirmdisable: true,
      isSpin: true,
      time_start: moment(),
    });
    let file = "";

    const formdata = new FormData();
    formdata.append("nach_token_id", tid);
    customFetch(process.env.REACT_APP_URL + "/deletetokenhousehold", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          isNACH: true,
          isNACHMessage: json.message,
          isSpin: false,
          cpn: 0,
        });
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
  }

  ImgchangeHandler(event, bank_id, nach_order_id, map_id) {
    //this.setState({ isSpin: true });
    this.setState({ isSpin: true, time_start: moment() });
    event.stopPropagation();
    //console.log("Change Image");
    //event.preventDefault();
    // const { api_token } =
    //   (this.props.location && this.props.location.state) || {};
    var file = event.target.files[0];
    //event.target.value = null;
    // console.log("bank_id", bank_id);
    // console.log("Order_id", nach_order_id);
    // console.log("FileNew", bank_id + "." + file.name.split(".").pop());
    // return;
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("order_id", nach_order_id);
    formdata.append("bank_id", bank_id);
    formdata.append("map_id", map_id)
    const requestOptions = {
      method: "POST",
      headers: {
        "api-token": api_token,
      },
      body: formdata,
    };
    customFetch(process.env.REACT_APP_URL + "/upload/nach/form", requestOptions)
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isNACH: true,
            isNACHMessage: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        // if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          NACH_form_upload: data,
          ISNACH_form_upload: true,
          isSpin: false,
        });
        //this.fetchDetails();
      })
      .catch((error) => console.log("error", error));
  }

  clearPhysicalNACH(bank_id) {
    this.setState({
      isSpin: true,
      time_start: moment(),
    });

    const formdata = new FormData();
    formdata.append("bank_id", bank_id);

    customFetch(process.env.REACT_APP_URL + "/clearPhysicalNACH", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isNACH: true,
            isNACHMessage: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((json) => {
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          isNACH: true,
          isNACHMessage: json.message,
          isSpin: false,
          cpn: 0,
        });
        this.forceUpdate();
      })
      .catch((error) => console.log("error", error));
  }
  handleChange11 = (value) => {
    this.setState({
      searchstatus: value,
    });
  };
  sendsmsmail(orderid, mapid, sendtype) {
    this.setState({ isSpin: true, time_start: moment() });
    const formdata = new FormData();
    formdata.append("inv_id", orderid);
    formdata.append("medium", sendtype);
    formdata.append("partner_vendor_payment_account_id", mapid);

    customFetch(process.env.REACT_APP_URL + "/send/notification", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          SMSemailSuccess: json.message,
          isSMSemail: true,
          isSpin: false,
        });
      })
      .catch((error) => console.log("error", error));
  }
  showNACHhistory = (hhnumber, loan_id) => {
    this.state.isSpin = true;
    this.state.temp_loan_id = loan_id;
    // AT-2143 | AT-2179 | Ankur Makavana | 13-07-2023 | conver post method to get method
    const params = {
      household_number: hhnumber,
    };
    const queryParams = new URLSearchParams(params).toString();

    customFetch(
      process.env.REACT_APP_URL +
        "/get/nach/registration/history?" +
        queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        // console.log(json);
        // if (json.api_code === 4 || json.api_code === 3 || json.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          isSpin: false,
          nach_history: json,
          isHistory: true,
        });
      })
      .catch((error) => console.log("error", error));
  };
  downloadfile(url) {
    // if (this.state.isAndroid) {
    //   window.Android.postMessage("ButtonClicked");
    // }
    if (window.Android && typeof window.Android.postMessage === "function") {
      // Call the postMessage function to send a message to Android
      window.Android.postMessage(url);
      this.setState({ isView: false });
    } else {
      console.error(
        "window.Android.postMessage is not a function or is not available."
      );
    }
    let filename = url.split("/").pop();
    customFetch(url, {
      headers: { "api-token": api_token },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); // Replace with the desired file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });

    //
    // let filename = path.split("/").pop();
    // // add texts as a href of <a> element after encoding.
    // console.log("zzz=>", URL.createObjectURL(new Blob([path], { type: 'application/json' })));
    // hidden_a.setAttribute("href", URL.createObjectURL(new Blob([path])));

    // // also set the value of the download attribute
    // hidden_a.setAttribute("download", filename);
    // document.body.appendChild(hidden_a);

    // // click the link element
    // hidden_a.click();
    // document.body.removeChild(hidden_a);
  }
  chkregisterdata = () => {
    console.log("this.state.filteredData", this.state.filteredData);
    this.state.filteredData.length > 0
      ? this.state.filteredData.map((fdata) => {
          console.log(
            "this.state.filteredData_1",
            fdata.partner_vendor_payment_account_id +
              "==" +
              this.state.collection_ac.split("~")[1]
          );
          if (
            fdata.nach_flag == "R" &&
            fdata.partner_vendor_payment_account_id ==
              this.state.collection_ac.split("~")[1]
          ) {
            this.setState({ disableBTN: true, errMsg: "NACH Already Present" });
          } else if (
            fdata.nach_flag == "R" &&
            fdata.nach_type == this.state.nachtyp &&
            fdata.partner_vendor_payment_account_id ==
              this.state.collection_ac.split("~")[1]
          ) {
            this.setState({ disableBTN: true, errMsg: "NACH Already Present" });
          } else {
            this.setState({ errMsg: "" });
            this.chkreqdata();
          }
        })
      : this.chkreqdata();
  };
  chkreqdata = () => {
    const enabled =
      this.state.nachtyp == "" || this.state.collection_ac.split("~")[0] == "";
    this.setState({
      disableBTN: enabled,
    });
  };
  render() {
    const {
      cnames,
      isCreateuser,
      isNACHMessage,
      isNACH,
      isView,
      viewFile,
      NACH_form_upload,
      ISNACH_form_upload,
      showSearch,
      isSMSemail,
      SMSemailSuccess,
      bankNachData,
    } = this.state;

    const { effective_designation_id } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            this.state.isHistory
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isHistory: false });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Bank Account</th>
                      <th>NACH Type</th>
                      <th>NACH Status</th>
                      <th>Vendor</th>
                      <th>Date</th>
                      <th>Operations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.nach_history &&
                      this.state.nach_history.map((history) => {
                        return (
                          <tr>
                            <td>{history.bank_account_number}</td>
                            <td>{history.nach_type_description}</td>
                            <td>{history.nach_status_type_description}</td>
                            <td>
                              {history.payment_vendor_name[0]} |{" "}
                              {history.partner_code}
                            </td>
                            <td>{history.added_at}</td>
                            <td>
                              {history.nach_status_type_flag == "E" ||
                              history.nach_status_type_flag == "J" ||
                              history.nach_status_type_flag == "D" ||
                              history.nach_status_type_flag == "R" ? (
                                "NA"
                              ) : history.nach_type == "P" ? (
                                history.signed_filename == null ||
                                history.signed_filename == "null" ||
                                history.signed_filename == "" ? (
                                  <>
                                    <div className="updownimg">
                                      <img
                                        src={require("../assets/images/view-fin.png")}
                                        className="add-new"
                                        onClick={
                                          () => {
                                            this.viewclick(history.filename);
                                          }
                                          // this.setState({
                                          //   viewFile: history.filename,
                                          //   isView: true,
                                          // })
                                        }
                                        alt=""
                                      />

                                      <div
                                        className="upload-img"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          // this.['upload'+item.cash_balance_id].click();
                                          this[
                                            "upload" + this.state.temp_loan_id
                                          ].click();
                                        }}
                                      >
                                        <img
                                          src={require("../assets/images/upload.png")}
                                          className="add-new"
                                          alt=""
                                        />
                                      </div>
                                      <input
                                        type="file"
                                        name="file"
                                        accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
                                        onChange={(event) => {
                                          event.preventDefault();
                                          this.setState({
                                            isSpin: false,
                                          });
                                          this.ImgchangeHandler(
                                            event,
                                            history.bank_id,
                                            history.physical_nach_order_id,
                                            history.partner_vendor_payment_account_id
                                          );
                                        }}
                                        //ref={(ref) => (this.upload = ref)}
                                        ref={(ref) => {
                                          this[
                                            "upload" + this.state.temp_loan_id
                                          ] = ref;
                                        }}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="updownimg">
                                      <img
                                        src={require("../assets/images/view-fin.png")}
                                        className="add-new"
                                        onClick={
                                          () => {
                                            this.viewclick(
                                              history.signed_filename
                                            );
                                          }
                                          // this.setState({
                                          //   viewFile: history.signed_filename,
                                          //   isView: true,
                                          // })
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </>
                                )
                              ) : (
                                "NA"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isSMSemail
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isSMSemail: false });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{SMSemailSuccess}</p>
            </div>
          </div>
        </div>

        <div
          className={
            ISNACH_form_upload
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ ISNACH_form_upload: false });
                  this.updata();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>
                {NACH_form_upload ? (
                  NACH_form_upload.razorpay_order_id ? (
                    <>
                      <strong>Order Id : </strong>
                      {NACH_form_upload.razorpay_order_id}
                      <br />
                      <strong>Payment Id : </strong>
                      {NACH_form_upload.razorpay_payment_id}
                      <br />
                    </>
                  ) : (
                    <>
                      <span>{NACH_form_upload.message}</span>
                    </>
                  )
                ) : (
                  ""
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            isView
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isView: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              {viewFile ? (
                <>
                  <iframe
                    className="pdfviewfile"
                    src={
                      viewFile +
                      "#toolbar=1&navpanes=0&scrollbar=0&page=1&zoom=25"
                    }
                  ></iframe>
                  <div className="popupdownload-btn">
                    <button
                      // href={viewFile}
                      // download={viewFile}
                      onClick={() => this.downloadfile(viewFile)}
                      className="btn btn-primary popupdownload"
                    >
                      Download
                    </button>
                  </div>
                </>
              ) : (
                "File Not Found"
              )}
            </div>
          </div>
        </div>
        <div
          className={
            isNACH
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isNACH: false });
                  this.updata();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{isNACHMessage}</p>
            </div>
          </div>
        </div>

        <div
          className={
            isCreateuser
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isCreateuser: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <form onChange={this.handleChange}>
                <div className="loanid-holder">
                  <span>Loan ID : {this.state.loan_id}</span>
                </div>
                <div className="loan-dataholder">
                  <div>HH No : {this.state.hhno}</div>
                  <div className="loandata-border">
                    Account No : {this.state.acno}
                  </div>
                  <div>
                    IFSC : {this.state.ifsc} ({this.state.bank_name})
                  </div>
                </div>
                <div className="selnach-holder">
                  <div className="row">
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <select
                              value={this.state.nachtyp}
                              className="form-control selnach"
                              name="nachtyp"
                              id=""
                              onChange={(e) =>
                                this.setState({ nachtyp: e.target.value }, () =>
                                  this.chkregisterdata()
                                )
                              }
                            >
                              <option value="">Select NACH Type</option>
                              <option value="P">Physical NACH</option>
                              <option value="D">Debit Card NACH</option>
                              <option value="N">Net Banking NACH</option>
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-sm">
                          <div className="form-group">
                            <select
                              value={this.state.product_id}
                              className="form-control selnach"
                              name="selectproduct"
                              id="selectproduct"
                              onChange={(e) =>
                                this.setState({
                                  product_id: e.target.value,
                                  pgdata: [],
                                  productdata: null,
                                  partnerpayment: [],
                                  isSpin: true
                                }, () =>
                                  this.getnachpayment()
                                )
                              }
                            >
                              <option value="">Select Product</option>
                              {this.state.productlist.map(
                                (product, index) => {
                                  return (
                                    <option key={index} value={product.product_id}>
                                      {product.product_name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-sm">
                          <PESelect
                            element_id={"SelectPartner"}
                            className="form-control selpartner"
                            // showSearch
                            //className="form-control"
                            value={this.state.productdata}
                            onChange={(e) => {
                              this.setState(
                                {
                                  productdata: e,
                                },
                                () => this.getpg()
                              );
                            }}
                            //onChange={(e) => console.log("E-->", e)}
                            placeholder="Select Partner"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option && option.label
                                ? option.label.toLowerCase()
                                : ""
                              ).includes(input.toLowerCase())
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA && optionA.label
                                ? optionA.label.toLowerCase()
                                : ""
                              ).localeCompare(
                                optionB && optionB.label
                                  ? optionB.label.toLowerCase()
                                  : ""
                              )
                            }
                            options={
                              this.state.partnerpayment &&
                              this.state.partnerpayment.map((item) => {
                                return {
                                  label: item.partner_name,
                                  value: item.partner_id,
                                };
                              })
                            }
                          />
                        </div>
                        <div class="col-sm">
                          <select
                            className="form-control selpartnergateway"
                            id="exampleFormControlSelect1"
                            name="collection_ac"
                            value={this.state.collection_ac}
                            onChange={(e) => {
                              this.setState(
                                { collection_ac: e.target.value },
                                () => this.chkregisterdata()
                              );
                            }}
                          >
                            <option value="">Select Payment Gateway</option>
                            {this.state.pgdata.map((pg, index) => {
                              return (
                                <option
                                  key={index}
                                  value={
                                    pg.bank_account_number + "~" + pg.map_id
                                  }
                                >
                                  {pg.payment_vendor_name +
                                    " / " +
                                    pg.bank_account_number}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    Loan Id: <strong>{this.state.loan_id}</strong>
                  </li>
                  <li className="list-group-item">
                    HH No.: <strong>{this.state.hhno}</strong>
                  </li>
                  <li className="list-group-item">
                    Account No.: <strong>{this.state.acno}</strong>
                  </li>
                  <li className="list-group-item">
                    IFSC:{" "}
                    <strong>
                      {this.state.ifsc} ({this.state.bank_name})
                    </strong>
                  </li>
                </ul> */}
                <div className="form-group regbtn-holder">
                  <button
                    className={
                      this.state.disableBTN ? "regbtn regbtnopacity" : "regbtn"
                    }
                    type="button"
                    disabled={this.state.disableBTN}
                    // disabled={
                    //   this.state.nachtyp === "" &&
                    //   this.state.collection_ac === ""
                    // }
                    // onClick={this.createuser}
                    onClick={this.setNACHHousehold}
                  >
                    Register
                  </button>
                  <div className="email-error">
                    {this.state.errMsg && this.state.errMsg}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">Search Household</div>
              {this.state.isAndroid ? (
                <></>
              ) : (
                <div className="log-out-report">
                  <img
                    src={require("../assets/images/power-off.png")}
                    onClick={() => logout(this.state.otherScreen)}
                    alt=""
                  />
                </div>
              )}
            </div>

            {showSearch ? (
              <div className="search-holder mt-3">
                <div className="container row">
                  <div className="col-2">
                    <input
                      className="form-control"
                      placeholder="Enter Household ID"
                      name="searchid"
                      onChange={(e) =>
                        this.setState({
                          datafordis: "hhid",
                          searchid: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-2">
                    <input
                      className="form-control"
                      type="date"
                      name="searchdate"
                      onChange={(e) =>
                        this.setState({
                          // datafordis: "hhid",
                          searchdate: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control"
                      name="searchstatus"
                      onChange={(e) =>
                        this.setState({
                          // datafordis: "hhid",
                          searchstatus: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Status</option>
                      {this.state.nach_status.map((status) => (
                        <option value={status.nach_status_type_flag}>
                          {status.nach_status_type_description}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3">
                    <select
                      className="form-control"
                      name="searchtype"
                      onChange={(e) =>
                        this.setState({
                          // datafordis: "hhid",
                          searchtype: e.target.value,
                        })
                      }
                    >
                      <option value="">Select NACH Type</option>
                      <option value="P">Physical</option>
                      <option value="N">Net Banking</option>
                      <option value="D">Debit Card</option>
                    </select>
                    {/* <input
                      className="form-control"
                      placeholder="Enter Household ID"
                      onChange={(e) =>
                        this.setState({
                          datafordis: "hhid",
                          searchid: e.target.value,
                        })
                      }
                    /> */}
                  </div>
                  <div className="col-1">
                    <Tooltip title="Search">
                      <img
                        src={require("../assets/images/searchicn.svg")}
                        className="searchbtn-nach"
                        onClick={() => this.fetchDetails()}
                      />
                    </Tooltip>
                  </div>
                  <div className="col-1">
                    <Tooltip title="Clear Cookies">
                      <img
                        src={require("../assets/images/clearcookie.svg")}
                        className="clearcookiebtn"
                        onClick={() => {
                          Cookies.remove("nachProductPartnerMap");
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <div className="search-holder mt-3">
                <div className="container float-right">
                  <div className="col-1">
                    <Tooltip title="Clear Cookies">
                      <img
                        src={require("../assets/images/clearcookie.svg")}
                        className="clearcookiebtn"
                        onClick={() => {
                          Cookies.remove("nachProductPartnerMap");
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group row">
              {/* <div className="col-sm-3">
                <select
                  className="form-control"
                  name="changeapi"
                  onChange={this.changeapi}
                >
                  <option>Select</option>
                  <option value="1">Disbused</option>
                  <option value="2">sanction</option>
                </select>
              </div> */}
              {/* <div className="col-sm-3">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/NACH_Mandate_Registration",
                      state: {
                        api_token: api_token,
                        // effective_designation_id: effective_designation_id,
                      },
                    });
                  }}
                >
                  Mandate Registration Result Upload
                </button>
              </div> */}
            </div>

            <div className="mt-3">
              {cnames.length > 0
                ? cnames.map((item, index) => (
                    <div className="card-holder mt-3" key={index}>
                      <div className="card-name-holder">
                        <div className="row">
                          <div className="col-4">
                            <div className="card-name">{item.borrower}</div>
                            <div className="card-hhid">
                              HH ID : {item.household_number}
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="card-hhid">
                              Bank Name : {item.bank_name}
                            </div>
                            <div className="card-hhid">
                              Account Number : {item.account_number}
                            </div>
                          </div>
                          {/*<div className="col-2">
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                this.clearCache(item.household_number)
                              }
                            >
                              Clear Cache
                            </button>
                            </div>*/}
                          {/* <div className="col-4">
                          <span className="card-status">
                            <div
                              className={
                                item.nach_status_type_flag === "I"
                                  ? "labelbg ibg"
                                  : item.nach_status_type_flag === "A"
                                    ? "labelbg ibg"
                                    : item.nach_status_type_flag === "R"
                                      ? "labelbg rbg"
                                      : item.nach_status_type_flag === "P"
                                        ? "labelbg pbg"
                                        : item.nach_status_type_flag === "U"
                                          ? "labelbg ubg"
                                          : item.nach_status_type_flag === "J" ||
                                            item.nach_status_type_flag === "D" || item.nach_status_type_flag === "E"
                                            ? "labelbg jbg"
                                            : "labelbg"
                              }
                            >
                              {item.nach_status_type_description}
                            </div>
                          </span>
                        </div> */}
                        </div>
                      </div>
                      <div className="branch-holder">
                        <span>{item.branch_name}</span>
                        {/* show payment vendor name AT-1716 */}
                        {/*{item.payment_vendor_name ? (
                          <span>{item.payment_vendor_name}</span>
                        ) : (
                          ""
                        )}*/}
                      </div>
                      {/* <div className="loan-detail">
                          <div>
                            Loan ID :
                            <span className="float-right">
                              {item.loan_id}
                            </span>
                          </div>
                        </div> */}
                      <div className="bank-label">NACH Details</div>
                      {/* <div className="bank-detail"> */}

                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              {/* <th>Bank Account</th> */}
                              <th>NACH Type</th>
                              <th>NACH Status</th>
                              <th>Vendor</th>
                              <th>Date</th>
                              <th>Operations</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bankNachData.length === 0
                              ? "No Data Found!"
                              : bankNachData.map((ndata) => {
                                  return item.bank_id === ndata.bank_id
                                    ? ndata.nach_data.map((nachdata) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>
                                                {nachdata.nach_type_description}
                                              </td>
                                              <td>
                                                {
                                                  nachdata.nach_status_type_description
                                                }
                                              </td>
                                              <td>
                                                {
                                                  nachdata
                                                    .payment_vendor_name[0]
                                                }{" "}
                                                | {nachdata.partner_code}
                                              </td>
                                              <td>{nachdata.added_at}</td>
                                              <td>
                                                {nachdata.nach_status_type_flag ===
                                                  "E" ||
                                                nachdata.nach_status_type_flag ===
                                                  "J" ||
                                                nachdata.nach_status_type_flag ===
                                                  "D" ||
                                                nachdata.nach_status_type_flag ===
                                                  "R" ? (
                                                  "NA"
                                                ) : nachdata.nach_type ===
                                                  "P" ? (
                                                  nachdata.signed_filename ==
                                                    null ||
                                                  nachdata.signed_filename ==
                                                    "null" ||
                                                  nachdata.signed_filename ==
                                                    "" ? (
                                                    <>
                                                      <div className="updownimg">
                                                        <img
                                                          src={require("../assets/images/view-fin.png")}
                                                          className="add-new"
                                                          onClick={
                                                            () => {
                                                              this.viewclick(
                                                                nachdata.filename
                                                              );
                                                            }
                                                            // this.setState({
                                                            //   viewFile:
                                                            //     history.filename,
                                                            //   isView: true,
                                                            // })
                                                          }
                                                          alt=""
                                                        />
                                                        <div
                                                          className="upload-img"
                                                          onClick={(event) => {
                                                            event.preventDefault();
                                                            // this.['upload'+item.cash_balance_id].click();
                                                            this[
                                                              "upload" +
                                                                ndata.bank_id
                                                            ].click();
                                                          }}
                                                        >
                                                          <img
                                                            src={require("../assets/images/upload.png")}
                                                            className="add-new"
                                                            alt=""
                                                          />
                                                        </div>
                                                        <input
                                                          type="file"
                                                          name="file"
                                                          accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
                                                          onChange={(event) => {
                                                            event.preventDefault();
                                                            this.setState({
                                                              isSpin: false,
                                                            });
                                                            this.ImgchangeHandler(
                                                              event,
                                                              ndata.bank_id,
                                                              nachdata.physical_nach_order_id,
                                                              nachdata.partner_vendor_payment_account_id
                                                            );
                                                          }}
                                                          //ref={(ref) => (this.upload = ref)}
                                                          ref={(ref) => {
                                                            this[
                                                              "upload" +
                                                                ndata.bank_id
                                                            ] = ref;
                                                          }}
                                                          style={{
                                                            display: "none",
                                                          }}
                                                        />
                                                        {/* AT-2110 | Vaibhav Sawant | 06-07-2023 | Functionality to allow to Expire Physical NACH for FIN-OPS */}
                                                        {//(effective_designation_id == 20) ?
                                                        process.env.REACT_APP_effective_designation_id.split(
                                                          ","
                                                        ).includes(
                                                          this.state.effective_designation_id.toString()
                                                        ) ? (
                                                          nachdata.nach_status_type_flag ==
                                                          "A" ? (
                                                            <button
                                                              className="btn btn-danger"
                                                              onClick={() =>
                                                                this.markExpire(
                                                                  ndata.bank_id,
                                                                  nachdata.third_reference_number_nach
                                                                )
                                                              }
                                                            >
                                                              Expire
                                                            </button>
                                                          ) : (
                                                            ""
                                                          )
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <div className="updownimg">
                                                        <img
                                                          src={require("../assets/images/view-fin.png")}
                                                          className="add-new"
                                                          onClick={
                                                            () => {
                                                              this.viewclick(
                                                                nachdata.signed_filename
                                                              );
                                                            }
                                                            // this.setState({
                                                            //   viewFile:
                                                            //     history.signed_filename,
                                                            //   isView: true,
                                                            // })
                                                          }
                                                          alt=""
                                                        />
                                                      </div>
                                                    </>
                                                  )
                                                ) : (
                                                  "NA"
                                                )}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })
                                    : "";
                                })}
                          </tbody>
                        </table>
                      </div>

                      {/* <div className="bankdetail-data">
                        Bank Name :
                        <span className="float-right">{item.bank_name}</span>
                      </div>
                      <div className="bankdetail-data tb-border">
                        Account No :
                        <span className="float-right">
                          {item.account_number}
                        </span>
                      </div> */}
                      {/* <div className="bankdetail-data">
                        Nach Type :
                        <span className="float-right">
                          {item.nach_status_type_flag !== "D"
                            ? item.nach_type === "P"
                              ? "Physical"
                              : item.nach_type === "N"
                                ? "Net Banking"
                                : item.nach_type === "D"
                                  ? "Debit Card"
                                  : ""
                            : ""}
                        </span>
                      </div> */}
                      {/* </div> */}

                      <div className="button-holder row">
                        {/* {item.nach_status_type_flag === "R" ? <></> : <></>} */}
                        <>
                          <div className="left-button col-6">
                            <div className="updownimg">
                              <img
                                src={require("../assets/images/add-fin.png")}
                                className="add-new"
                                onClick={() => this.showNACHPopup(item)}
                                alt=""
                              />
                            </div>

                            {/* {// item.nach_token_id === "" ||
                            // item.nach_token_id === null ? (
                            //   <>
                            //   </>
                            // ) :
                            item.nach_flag === 1 ||
                            item.nach_status_type_flag === "R" ? (
                              // <button className="btn btn-primary">Collect</button>
                              // <div className="updownimg">
                              //   <img
                              //     src={require("../assets/images/pull-money.png")}
                              //     className="add-new"
                              //     onClick={() =>
                              //       this.setState({
                              //         ispullAmt: true,
                              //         pullhousehold_id: item.household_id,
                              //         pullaccount_number:
                              //           item.account_number,
                              //         pullloan_id: item.loan_id,
                              //       })
                              //     }
                              //     alt=""
                              //   />
                              // </div>
                              <></>
                            ) : //item.nach_token_id
                            item.nach_status_type_flag === "J" ||
                              item.nach_status_type_flag === "C" ||
                              item.nach_status_type_flag === "I" ||
                              item.nach_status_type_flag === "A" ? (
                              <>
                                <div className="updownimg">
                                  <img
                                    src={require("../assets/images/add-fin.png")}
                                    className="add-new"
                                    onClick={() => this.showNACHPopup(item)}
                                    alt=""
                                  />
                                </div>
                              </>
                            ) : (
                              ""
                              // <label className="nach-file-label">0</label>
                            )} */}
                          </div>
                          <div className="right-button col-6">
                            <div className="sms-email-holder">
                              {//effective_designation_id == 20 ? (
                              process.env.REACT_APP_effective_designation_id.split(
                                ","
                              ).includes(
                                this.state.effective_designation_id.toString()
                              ) ? (
                                item.nach_order_id && item.nach_partner_id ? (
                                  <>
                                    <div className="sms-holder loan_data">
                                      <img
                                        src={require("../assets/images/comment.png")}
                                        className="smsicn"
                                        onClick={() =>
                                          this.sendsmsmail(
                                            item.nach_order_id,
                                            item.nach_partner_id,
                                            "sms"
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="email-holder loan_data">
                                      <img
                                        src={require("../assets/images/mail.png")}
                                        className="mailicn m-1"
                                        onClick={() =>
                                          this.sendsmsmail(
                                            item.nach_order_id,
                                            item.nach_partner_id,
                                            "email"
                                          )
                                        }
                                      />
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {/* {item.nach_type && (
                              <div className="NACH-history loan_data">
                                <img
                                  src={require("../assets/images/check-list.png")}
                                  className="mailicn m-1"
                                  onClick={() =>
                                    this.showNACHhistory(
                                      item.household_number,
                                      item.loan_id
                                    )
                                  }
                                />
                              </div>
                            )} */}
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </section>

        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(NACH_Household_list);
