import React, { Component } from "react";
import { withRouter } from "react-router";
import { logout } from "./Utility";
import customFetch from "./apicall/api";

import ChatSupportBtn from "./ChatSupportBtn";

class To_slip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_unit_level_id: "",
      parent_entity_name: "",
      max_unit_level_id: "",
      entity_name: "",
      effective_designation_id: "",
    };
  }
  componentDidMount() {
    document.title = "Dashboard";
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    const { api_token, effective_designation_id } =
      (this.props.location && this.props.location.state) || {};
    this.setState({
      effective_designation_id: effective_designation_id,
    });
    // const formdata = new FormData();
    // formdata.append("userid", uid);
    // customFetch(process.env.REACT_APP_URL + "/getrole", {
    //   method: "POST",
    //   headers: { "api-token": api_token },
    //   body: formdata,
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data.api_code === 4) {
    //       localStorage.clear();
    //       this.props.history.push("/");
    //     }
    //     console.log(data.max_unit_level_id);
    //     this.setState({
    //       branch_unit_level_id: data.max_unit_level_id,
    //       parent_entity_name: data.parent_entity_name,
    //       max_unit_level_id: data.max_unit_level_id,
    //       effective_designation_id: data.effective_designation_id,
    //     });
    //     localStorage.setItem("e_designation_id", data.effective_designation_id);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }
  // logout(){
  //   localStorage.clear();
  //   this.props.history.push('/');
  // }
  render() {
    // console.log("Local storage uid",uid)
    const { effective_designation_id } = this.state;
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    return (
      <>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="logo-area">
              <button
                className="back_button_toslip"
                onClick={() => this.props.history.goBack()}
              >
                <img src={require("../assets/images/back.png")} />
              </button>
              <img src={require("../assets/images/aceN Logo.png")} alt="" />
              <div className="log-out1">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>

            <div className="dashboard-card-holder">
              <div
                className="card card-border-small col-sm-6 py-2 toslip"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/To_deposit_slip",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-toslip">
                    <img src={require("../assets/images/invoice.png")} alt="" />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>TO Slip</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2 nachreg"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/NACH_Household_list",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-nachreg">
                    <img
                      src={require("../assets/images/Ellipse 91.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>NACH Register</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2 down"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/NACH_Download",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-down">
                    <img
                      src={require("../assets/images/download.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>Download</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2 puldown"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/NACH_Pull_Download",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-puldown">
                    <img
                      src={require("../assets/images/sort-down.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>NACH Pull Upload</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2 prepull"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/NACH_Preparation_Forpull",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-prepull">
                    <img
                      src={require("../assets/images/to-do-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>
                      NACH Preparation
                      <br />
                      For pull
                    </h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2 failcase"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/NACH_Fail_Cases",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-failcase">
                    <img src={require("../assets/images/shield.png")} alt="" />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>NACH Failed Cases</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2 ndeac"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/NACH_Deactivate",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-ndeac">
                    <img src={require("../assets/images/toggle.png")} alt="" />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>NACH Deactivate</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/PartnerLOSDownloads",
                    state: {
                      api_token: api_token,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>LOS Download</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/Disbursement",
                    state: {
                      api_token: api_token,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>Disburse Case</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>

              <div
                className="card card-border-small col-sm-6 py-2 nachreg"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/NACH_Mandate_Registration",
                    state: {
                      api_token: api_token,
                      effective_designation_id: effective_designation_id,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-nachreg">
                    <img
                      src={require("../assets/images/Ellipse 91.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading whitefont">
                    <h2>Mandate Registration Result Upload</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>

              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/utr",
                    state: {
                      api_token: api_token,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>UTR Upload</h2>
                  </div>
                  {/* <div className="card-subheading">
                    <h2>Check your transaction history.<br/>You can find a list of your transactions</h2>
                  </div> */}
                  <div className="text-center"></div>
                </div>
              </div>

              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/ekyc",
                    state: {
                      api_token: api_token,
                    },
                  });
                }}
              >
                <div className="card-body">
                  <div className="image-holder-4">
                    <img
                      src={require("../assets/images/check-list.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>EKyc</h2>
                  </div>
                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(To_slip);
